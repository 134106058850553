@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700,900|Noto+Serif+JP:200,300,400,500,600,700,900&amp;subset=japanese);
.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 400;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: #111111;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "游ゴシック", YuGothic, "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-style: normal;
  font-weight: bold;
  word-wrap: break-word;
  font-size: 16px;
  min-width: 100%;
  overflow: hidden;
  background: #111111;
}

.inner {
  max-width: 1200px;
  margin: 0 auto;
}

@media only screen and (max-width: 640px) {
  .inner {
    padding: 20px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .inner {
    padding: 64px 0 20px;
  }
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
  border-top: 10px solid #f92d16;
}

#contents {
  width: 100%;
  max-width: 1200px;
  padding-bottom: 120px;
}

@media only screen and (max-width: 834px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media screen and (min-width: 835px) and (max-width: 1200px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

a {
  transition: 1s;
}

.imgR, .imgL {
  float: none	!important;
  margin-left: 0;
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    max-width: 100% !important;
    text-align: center;
  }
}

.imgL {
  float: none;
  max-width: 100% !important;
}

.mincho {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "游ゴシック", YuGothic, "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  text-align: center;
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

.hed_Lwrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;
}

@media screen and (min-width: 835px) and (max-width: 1200px) {
  .hed_Lwrap {
    width: 100%;
  }
}

@media only screen and (max-width: 834px) {
  .hed_Lwrap {
    width: 100%;
  }
}

@media only screen and (max-width: 834px) {
  .hed_L {
    width: 74%;
    margin: 0 auto;
    padding-bottom: 10px;
  }
}

.hed_R {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 40%;
}

@media only screen and (max-width: 834px) {
  .hed_R {
    justify-content: center;
    width: 100%;
  }
}

@media screen and (min-width: 835px) and (max-width: 1200px) {
  .hed_R {
    justify-content: flex-end;
    width: 100%;
  }
}

.hed_R .hed_sns {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hed_R .fb {
  margin-right: 10px;
}

.hed_R .insta {
  margin-right: 50px;
}

.hed_R .tel2 {
  font-size: 1.8em;
  color: #ffffff;
  margin-right: 30px;
  font-family: "Times New Roman";
}

@media only screen and (max-width: 834px) {
  .hed_R .tel2 {
    margin-right: 0;
  }
}

@media screen and (min-width: 835px) and (max-width: 1200px) {
  .hed_R .tel2 {
    margin-right: 0;
  }
}

.hed_R .tel2 a {
  color: #ffffff;
  font-weight: bold;
  display: block;
}

.hed_R .tel2 i {
  margin-right: 7px;
}

#header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 10px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(17, 17, 17, 0.7);
}

@media only screen and (max-width: 834px) {
  #header {
    display: block;
    padding: 50px 10px;
    position: inherit;
  }
}

@media screen and (min-width: 835px) and (max-width: 1200px) {
  #header {
    display: block;
  }
}

#header #siteID {
  text-align: center;
}

@media only screen and (max-width: 834px) {
  #header #siteID {
    text-align: center;
  }
}

@media only screen and (max-width: 640px) {
  #header #siteID {
    margin-bottom: 10px;
  }
}

#header #siteID a {
  opacity: 1;
  display: block;
}

#nav_global {
  width: 100%;
}

@media only screen and (max-width: 834px) {
  #nav_global {
    display: none;
  }
}

#nav_global .inner {
  padding: 0;
}

#nav_global ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#nav_global ul .pcnone {
  display: none;
}

#nav_global ul .spnone {
  display: block;
}

@media only screen and (max-width: 640px) {
  #nav_global ul .spnone {
    display: none;
  }
}

#nav_global ul li {
  z-index: 1;
  position: relative;
}

#nav_global ul li a {
  display: block;
  color: #ffffff;
  padding: 10px;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  #nav_global ul li a {
    font-size: 14px;
  }
}

#nav_global ul li a:hover {
  opacity: 1;
  -webkit-animation: .3s;
  animation: zoom .3s;
}

#nav_global ul ul {
  position: absolute;
  width: 188px;
  z-index: 100;
  display: block;
  background: white;
  text-align: center;
  left: 0;
  right: 0;
}

#nav_global ul ul li {
  border-bottom: 1px solid #333 !important;
  font-size: 14px;
  border-bottom: none;
  border-left: none !important;
}

#nav_global ul ul li a {
  color: #111111;
  padding: 15px 0;
}

@media only screen and (max-width: 640px) {
  #mainArea {
    margin: auto;
  }
}

#mainArea img {
  width: 100% !important;
}

#mainArea2 .blog_title {
  color: #fff;
  font-size: 3em;
  z-index: 1;
  position: relative;
  text-align: center;
  font-weight: bold;
  line-height: 0.9em;
  width: 100%;
  margin: 0 auto;
  padding: 250px 10px 150px;
  font-family: 'Sorts Mill Goudy', serif;
  max-width: 1200px;
  margin: 0 auto;
  text-align: right;
}

@media only screen and (max-width: 834px) {
  #mainArea2 .blog_title {
    padding: 100px 10px;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  #mainArea2 .blog_title {
    padding: 245px 10px 70px;
  }
}

#mainArea2 .blog_title span {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  display: block;
  font-size: 20px;
}

#mainArea2 .blog_title:empty {
  display: none;
}

@media only screen and (max-width: 834px) {
  #mainArea2 .blog_title {
    font-size: 30px;
  }
}

#local-keyvisual {
  position: relative;
  background-position: center top;
  z-index: 0;
}

#local-keyvisual:empty {
  display: none;
}

#local-keyvisual h1 {
  color: #fff;
  font-size: 3em;
  z-index: 1;
  position: relative;
  text-align: center;
  font-weight: bold;
  line-height: 0.9em;
  width: 100%;
  margin: 0 auto;
  padding: 250px 10px 150px;
  font-family: 'Sorts Mill Goudy', serif;
  max-width: 1200px;
  margin: 0 auto;
  text-align: right;
}

@media only screen and (max-width: 834px) {
  #local-keyvisual h1 {
    padding: 100px 10px;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  #local-keyvisual h1 {
    padding: 245px 10px 70px;
  }
}

#local-keyvisual h1 span {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  display: block;
  font-size: 20px;
}

#local-keyvisual h1:empty {
  display: none;
}

@media only screen and (max-width: 834px) {
  #local-keyvisual h1 {
    font-size: 30px;
  }
}

#slider {
  position: relative;
  z-index: 1;
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-size: cover;
  background-position: center;
}

#local-keyvisual #local-keyvisual-bg:empty, #local-keyvisual .local-keyvisual-bg:empty {
  display: none;
}

#mainArea2 #local-keyvisual-title {
  background: url(../images/blog/local_main06.jpg) no-repeat;
  background-size: cover;
}

#mainArea2 #local-keyvisual-title:empty {
  display: none;
}

.pan1 {
  margin: 20px 0 100px;
  color: #fff;
  text-align: center;
}

@media only screen and (max-width: 834px) {
  .pan1 {
    margin: 20px auto 60px;
  }
}

.pan1 a {
  color: #fff;
}

.toggleMenu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 49px;
  width: 49px;
  background-size: contain;
  position: fixed;
  top: 5px;
  right: 5px;
  z-index: 100;
  transition: 0.5s ease-in-out;
  background: #fff;
  border-radius: 10px;
}

.toggleMenu:after {
  content: 'MENU';
  font-size: 10px;
  color: #ffffff;
  display: block;
  position: absolute;
  bottom: -50%;
  left: 8%;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .toggleMenu {
    width: 49px;
  }
}

.hide {
  transform: translateY(-200%);
}

.toggleMenu .bars {
  margin: 0 30px 0 15px;
  height: 22px;
  width: 35px;
  position: relative;
}

.toggleMenu a:hover {
  opacity: 1;
}

.toggleMenu .bars span {
  height: 3px;
  background-color: #f92d16;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  position: absolute;
  left: -5px;
}

.toggleMenu .bars span:nth-of-type(1) {
  width: 30px;
  top: 0;
}

.toggleMenu .bars span:nth-of-type(2) {
  width: 30px;
  top: 10px;
}

.toggleMenu .bars span:nth-of-type(3) {
  width: 30px;
  top: 20px;
}

.toggleMenu.isActive .bars span {
  right: 0;
}

.toggleMenu.isActive .bars span:nth-of-type(1), .toggleMenu.isActive .bars span:nth-of-type(3) {
  width: 30px;
  top: 10px;
}

.toggleMenu.isActive .bars span:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.toggleMenu.isActive .bars span:nth-of-type(2) {
  opacity: 0;
}

.toggleMenu.isActive .bars span:nth-of-type(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.overlayMenu {
  width: 100%;
  -webkit-transition: -webkit-transform .8s ease;
  transition: -webkit-transform .8s ease;
  transition: transform .8s ease;
  transition: transform .8s ease, -webkit-transform .8s ease;
  z-index: 10;
  position: fixed;
  top: 0;
  right: 100%;
  bottom: 0;
  opacity: 0;
  transition: opacity .6s ease, visibility .6s ease;
}

.fixed .overlayMenu {
  visibility: visible;
  opacity: 1;
}

.overlayMenu.isOpened {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.overlayMenu > .outer {
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-size: cover;
  padding: 150px 100px !important;
  background: #111111;
}

.overlayMenu > .outer .inner {
  padding: 0 100px;
}

.overlayMenu > .outer .sp_hed_right {
  margin-top: 80px;
}

@media only screen and (max-width: 834px) {
  .overlayMenu > .outer .sp_hed_right {
    display: block;
  }
  .overlayMenu > .outer .sp_hed_right .snswrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  }
  .overlayMenu > .outer .sp_hed_right .snswrap .fb {
    margin-right: 10px;
  }
  .overlayMenu > .outer .sp_hed_right .tel2 {
    font-size: 1.5em;
    justify-content: center;
    display: flex;
    align-items: center;
    color: #ffffff;
    font-family: "Times New Roman";
  }
  .overlayMenu > .outer .sp_hed_right .tel2 a {
    color: #ffffff;
  }
  .overlayMenu > .outer .sp_hed_right .tel2 i {
    margin-right: 7px;
  }
}

@media only screen and (max-width: 834px) {
  .overlayMenu > .outer {
    padding: 20px 0;
  }
}

.overlayMenu > .outer li {
  border-bottom: 1px solid #f92d16;
}

.overlayMenu > .outer li a {
  color: #ffffff;
  padding: 20px;
  display: block;
  font-size: 24px;
  font-weight: bold;
  font-family: 'Sorts Mill Goudy', serif;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

@media (max-width: 835px) {
  .pcOnly {
    display: none !important;
  }
}

@media (min-width: 835px) {
  .smOnly {
    display: none !important;
  }
}

.fixed_cont_menu {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(58, 188, 174, 0.7);
  padding: 10px;
  z-index: 1;
  border-top: 5px solid #f92d16;
}

.fixed_cont_menu .pccont_btn_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}

@media only screen and (max-width: 834px) {
  .fixed_cont_menu .pccont_btn_wrap {
    padding: 0;
  }
}

.fixed_cont_menu .pccont_btn_wrap li {
  margin: 0 5px;
}

@media only screen and (max-width: 834px) {
  .fixed_cont_menu .pccont_btn_wrap li {
    margin: 0 5px;
  }
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  text-align: center;
  color: #ffffff;
}

@media only screen and (max-width: 834px) {
  footer#global_footer {
    margin-bottom: 50px;
  }
}

footer#global_footer .inner {
  padding: 70px 10px;
}

@media only screen and (max-width: 834px) {
  footer#global_footer .inner {
    padding: 30px 0;
  }
}

footer#global_footer a {
  text-decoration: none;
}

footer#global_footer #nav_footer {
  background: #f92d16;
}

@media only screen and (max-width: 834px) {
  footer#global_footer #nav_footer {
    padding: 30px 0;
  }
}

footer#global_footer #nav_footer ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 !important;
}

@media only screen and (max-width: 834px) {
  footer#global_footer #nav_footer ul {
    display: block;
  }
}

@media screen and (min-width: 835px) and (max-width: 1200px) {
  footer#global_footer #nav_footer ul {
    flex-wrap: wrap;
    justify-content: center;
  }
}

footer#global_footer #nav_footer li {
  width: 100%;
}

@media screen and (min-width: 835px) and (max-width: 1200px) {
  footer#global_footer #nav_footer li {
    width: 18%;
  }
}

footer#global_footer #nav_footer li a {
  text-decoration: none;
  padding: 10px 0;
  display: block;
  color: #ffffff;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

@media only screen and (max-width: 834px) {
  footer#global_footer #nav_footer li a {
    background: #f92d16;
    color: #ffffff;
    border-radius: 5px;
    margin: 5px auto;
    width: 300px;
  }
}

footer#global_footer #nav_footer li a:hover {
  opacity: 0.6;
}

footer#global_footer #copy a {
  text-decoration: none;
  color: #fff;
}

footer#global_footer #copy a:hover {
  opacity: 0.6;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  footer#global_footer #copy a {
    line-height: 60px;
  }
}

.hotpaper {
  text-align: left;
}

@media only screen and (max-width: 834px) {
  .hotpaper {
    margin: 30px 0;
    text-align: center;
  }
}

#copy {
  color: #fff;
}

.pagetop {
  position: fixed;
  right: 10px;
  bottom: 8%;
  z-index: 2;
  opacity: 1;
}

.pagetop a {
  background: #f92d16;
  height: 60px;
  width: 60px;
  display: block;
  border-radius: 5px;
  color: #ffffff;
  padding: 15px;
  font-size: 1.5em;
}

.small_bg {
  background: transparent;
}

#footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  #footer {
    display: block;
  }
}

#footer1 {
  background: url(../images/home/contact_bg.jpg) no-repeat;
  background-size: cover;
  padding: 10px !important;
  margin-bottom: 100px;
}

@media only screen and (max-width: 640px) {
  #footer1 {
    padding: 20px !important;
  }
}

#footer1 .tel2 {
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: 1.8em;
  justify-content: center;
  font-family: "Times New Roman";
}

@media only screen and (max-width: 640px) {
  #footer1 .tel2 {
    margin-right: 0;
  }
}

#footer1 .tel2 a {
  color: #ffffff;
  font-weight: bold;
}

#footer1 .tel2 i {
  margin-right: 7px;
}

@media only screen and (max-width: 640px) {
  #footer1 .ftcont {
    margin-top: 20px;
  }
}

#footer1 .ftcont a {
  background: #f92d16;
  color: #ffffff;
  font-size: 1.3em;
  padding: 15px 30px;
  border-radius: 5px;
  font-weight: bold;
}

@media only screen and (max-width: 834px) {
  #footer1 .ftcont a {
    font-size: 1em;
    padding: 10px;
    margin-top: 15px;
  }
}

.ftcont_wrap {
  padding: 70px !important;
  border: 1px solid #ffffff;
}

.ftcont_wrap .fth2 {
  font-size: 1.5em;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  margin-bottom: 30px;
}

.ftcont_wrap .ft_txt {
  color: #ffffff;
  margin-bottom: 30px;
}

@media only screen and (max-width: 640px) {
  .ftcont_wrap {
    padding: 70px 10px !important;
  }
}

#footer2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  #footer2 {
    display: block;
    padding: 50px 10px !important;
  }
  #footer2 .ft_R {
    width: 100% !important;
  }
  #footer2 .ft_L {
    width: 100% !important;
  }
}

#footer2 .ft_R {
  width: 44%;
}

#footer2 .ft_L {
  width: 54%;
}

#footer2 .ft_L .ft_shop_info {
  text-align: left;
}

#footer2 .ft_L .ft_shop_info a {
  color: #ffffff;
}

#footer2 .ft_L .ft_shop_info dt {
  width: 40%;
  position: relative;
}

#footer2 .ft_L .ft_shop_info dt:after {
  content: '/';
  position: absolute;
  right: 0;
  top: 0;
}

#footer2 .ft_L .ft_shop_info dd {
  width: 60%;
  margin-left: 50px;
}

#footer2 .ft_L .ft_shop_info dl {
  display: flex;
  justify-content: flex-start;
  margin: 10px 0;
}

.ftwrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 50px 10px !important;
}

@media only screen and (max-width: 834px) {
  .ftwrap {
    display: block;
    padding: 30px !important;
  }
}

@media screen and (min-width: 834px) and (max-width: 1000px) {
  .ftwrap {
    display: block;
    padding: 30px !important;
  }
}

.ftlogo {
  margin-bottom: 15px;
  text-align: left;
}

.ftmap iframe {
  width: 100%;
  height: 500px;
}

/* box */
.h2_01 h2 {
  font-size: 1.75em;
  line-height: 1.3em;
  color: #ffffff;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.h2_02 h2 {
  font-size: 2.5em;
  font-weight: bold;
  line-height: 1.3em;
  font-family: 'Sorts Mill Goudy', serif;
  position: relative;
}

.h2_02 h2:after {
  content: url(../images/home/shape_under.png);
  display: block;
  position: absolute;
  top: 25%;
  left: 0;
}

.h2_02 h2 span {
  font-size: 13px;
  margin-left: 30px;
}

@media only screen and (max-width: 834px) {
  .h2_02 h2 {
    font-size: 2em;
  }
}

.h2_03 h2 {
  font-size: 2.5em;
  font-weight: bold;
  line-height: 1.3em;
  font-family: 'Sorts Mill Goudy', serif;
  color: #f92d16;
}

@media only screen and (max-width: 640px) {
  .h2_03 h2 {
    font-size: 1.2em;
  }
}

.txt {
  line-height: 2em;
}

.btn a {
  display: block;
  padding: 20px;
  color: #ffffff;
  max-width: 310px;
  font-weight: bold;
  position: relative;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  border: 4px double #f92d16;
}

.btn a:hover {
  background: #f92d16;
  border: 4px double #ffffff;
}

@media only screen and (max-width: 640px) {
  .btn a {
    max-width: 100%;
  }
}

.btn a:before {
  font-family: "Font Awesome 5 pro";
  content: "\f054";
  /*アイコンのユニコード*/
  position: absolute;
  /*絶対位置*/
  font-size: 1em;
  /*サイズ*/
  right: 2%;
  top: 30%;
  color: #ffffff;
  /*アイコン色*/
}

.sec_01wrap {
  background: url(../images/home/cont1_bg.jpg) no-repeat center;
  background-size: cover;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

.sec_01 {
  color: #ffffff;
  padding: 120px 10px;
}

@media only screen and (max-width: 640px) {
  .sec_01 {
    padding: 100px 10px;
    background-color: rgba(51, 3, 0, 0.3);
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .sec_01 {
    background-color: rgba(51, 3, 0, 0.3);
  }
}

.sec_02wrap {
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

@media only screen and (max-width: 640px) {
  .sec_02wrap {
    margin-top: 9%;
  }
}

.sec_02 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: #ffffff;
}

@media only screen and (max-width: 640px) {
  .sec_02 {
    display: block;
  }
  .sec_02 .flxL {
    width: 100% !important;
  }
  .sec_02 .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
}

.sec_02 .flxL {
  width: 50%;
}

.sec_02 .flxL img {
  width: auto !important;
  height: 550px !important;
  object-fit: cover;
  font-family: 'object-fit:cover;';
}

.sec_02 .flxR {
  width: 50%;
  max-width: 435px;
  margin: 7% auto 0;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .sec_02 .flxR {
    margin: 15% 10%;
  }
}

.sec_03wrap {
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

@media only screen and (max-width: 640px) {
  .sec_03wrap {
    margin-top: 9%;
  }
}

.sec_03 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: #ffffff;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 640px) {
  .sec_03 {
    display: block;
  }
  .sec_03 .flxL {
    width: 100% !important;
  }
  .sec_03 .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
}

.sec_03 .flxL {
  width: 50%;
}

.sec_03 .flxL img {
  width: auto !important;
  height: 550px !important;
  object-fit: cover;
  font-family: 'object-fit:cover;';
}

.sec_03 .flxR {
  width: 50%;
  max-width: 435px;
  margin: 7% auto 0;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .sec_03 .flxR {
    margin: 15% 10%;
  }
}

.sec_04 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: #ffffff;
}

@media only screen and (max-width: 834px) {
  .sec_04 {
    display: block;
  }
  .sec_04 .flxL {
    width: 100% !important;
  }
  .sec_04 .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
}

.sec_04 .flxL {
  width: 48%;
}

.sec_04 .flxR {
  width: 48%;
}

.bnrbox {
  justify-content: space-between;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .bnrbox {
    justify-content: center;
  }
}

.bnrbox .box {
  width: 23%;
}

.bnrbox .box img {
  width: 100% !important;
}

@media only screen and (max-width: 640px) {
  .bnrbox .box {
    width: 48% !important;
    margin: 15px 0;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .bnrbox .box {
    width: 46%;
    margin: 15px;
  }
}

.sec_05 {
  display: flex;
  justify-content: space-between;
  color: #ffffff;
}

@media only screen and (max-width: 834px) {
  .sec_05 {
    display: block;
  }
  .sec_05 .flxL {
    width: 100% !important;
    padding: 30px !important;
  }
  .sec_05 .flxR {
    width: 100% !important;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  .sec_05 {
    display: block;
  }
  .sec_05 .flxL {
    width: 100% !important;
    padding: 30px !important;
  }
  .sec_05 .flxR {
    width: 100% !important;
  }
}

.sec_05 .flxL {
  width: 50%;
  background: url(../images/home/tempo_bg.jpg);
  background-size: cover;
  padding: 50px;
}

.sec_05 .flxR {
  width: 50%;
  max-height: 700px;
  min-height: 525px;
}

.list li {
  position: relative;
  padding: 20px;
  font-size: 20px;
  font-weight: bold;
  color: #333;
  display: flex;
  align-items: center;
}

.list li:before {
  content: url(../images/common/check_icon.png);
  margin-right: 10px;
}

.btn_list li {
  width: 30%;
}

@media only screen and (max-width: 640px) {
  .btn_list li {
    width: 100%;
    margin: 5px 0;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .btn_list li {
    width: 33%;
  }
}

@media screen and (min-width: 834px) and (max-width: 1000px) {
  .btn_list li {
    width: 33%;
  }
}

.btn_list li a {
  background: #f92d16;
  border-radius: 10px;
  padding: 10px;
  color: #ffffff;
  font-weight: bold;
  display: block;
  width: 140px;
}

@media only screen and (max-width: 640px) {
  .btn_list li a {
    width: 100%;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .btn_list li a {
    width: 118px;
    font-size: 13px;
  }
}

@media screen and (min-width: 834px) and (max-width: 1000px) {
  .btn_list li a {
    width: 118px;
    font-size: 13px;
  }
}

.img-animation {
  overflow: hidden;
  position: relative;
}

.img-animation.animated:after {
  -webkit-animation: img-animation 1.5s cubic-bezier(0.77, 0, 0.175, 1) both;
  animation: img-animation 1.5s cubic-bezier(0.77, 0, 0.175, 1) both;
  background: #fff;
  bottom: 0;
  content: '';
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

@-webkit-keyframes img-animation {
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@keyframes img-animation {
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.newswrap .news {
  height: 330px;
  overflow-y: auto;
}

@media only screen and (max-width: 834px) {
  .newswrap .news {
    padding-left: 0;
    font-size: 16px;
  }
}

.newswrap .news dt {
  border: none !important;
  color: #ffffff;
  display: block !important;
  text-align: center;
  margin-bottom: 10px;
}

@media only screen and (max-width: 640px) {
  .newswrap .news dt {
    width: 100% !important;
  }
}

.newswrap .news dd {
  border: none !important;
  padding: 0 !important;
  width: 100% !important;
  color: #ffffff;
}

.newswrap .news dl {
  display: block !important;
  margin-bottom: 30px;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #fff;
  border: none;
  border-radius: 10px;
  box-shadow: inset 0 0 2px #777;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
  box-shadow: none;
}

.top_blog {
  width: 100%;
}

.blog_list {
  height: 250px;
  overflow-y: auto;
  border: none !important;
  width: 100%;
}

.blog_list .detail {
  display: none;
}

.blog_list .blog_photo {
  display: none;
}

.blog_list .blog_photo img {
  width: 180px !important;
  height: auto !important;
  object-fit: cover;
  font-family: "object-fit:cover;";
}

@media only screen and (max-width: 640px) {
  .blog_list .blog_photo img {
    width: 100% !important;
  }
}

.blog_list .blog_text {
  font-size: 16px !important;
  padding: 20px 0 !important;
}

@media only screen and (max-width: 834px) {
  .blog_list .blog_text {
    display: block !important;
    width: 100% !important;
  }
}

.blog_list .blog_text h3 {
  width: 100%;
  font-size: 16px !important;
  margin-bottom: 0 !important;
}

@media only screen and (max-width: 640px) {
  .blog_list .blog_text h3 {
    width: 100% !important;
  }
}

.blog_list .blog_text h3 a {
  color: #f92d16 !important;
}

.blog_list .blog_text h3 a:hover {
  text-decoration: underline;
}

.blog_list .blog_date {
  width: 150px !important;
  color: #ffffff !important;
  background: #f92d16 !important;
  text-align: center;
}

div.blog_list > div {
  border-bottom: 1px dashed #333 !important;
}

@media only screen and (max-width: 834px) {
  div.blog_list > div {
    flex-direction: row !important;
  }
}

.home_td {
  margin-left: -7.69231% !important;
  display: inline-table;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .home_td {
    margin-left: -7.69231% !important;
  }
}

@media only screen and (max-width: 640px) {
  .home_td {
    margin-left: 0 !important;
  }
}

.home_td .box {
  z-index: 10;
  float: left;
  width: 42.30769% !important;
  margin-left: 7.69231% !important;
  margin-bottom: 30px !important;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .home_td .box {
    width: 42.30769% !important;
    margin-left: 7.69231% !important;
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .home_td .box {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

.home_td .box img {
  width: 100% !important;
  border-radius: 15px;
}

.home_td .box .cont_titl {
  display: block;
  margin: 30px 0 10px;
}

.home_td .box .cont_titl h3 {
  font-size: 25px;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .home_td .box .cont_titl h3 {
    font-size: 20px;
  }
}

.home_td .box .cont_titl h3:first-letter {
  font-size: 35px;
  color: #ef9916;
}

@media only screen and (max-width: 640px) {
  .home_td .box .cont_titl h3:first-letter {
    font-size: 30px;
  }
}

/* title */
div.sns_list {
  display: -ms-flexbox;
  display: flex;
  flex-direction: inherit;
  border: none;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-direction: inherit;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

div.sns_list > div {
  width: calc(100% / 3 - 1%);
  border-bottom: none;
}

@media only screen and (max-width: 640px) {
  div.sns_list > div {
    width: 48%;
  }
}

div.sns_list > div .sns_text {
  display: none;
}

.insta div.sns_list > div {
  flex-direction: column;
  -ms-flex-direction: column;
}

.insta div.sns_list > div .sns_photo {
  padding: 10px;
  width: 100% !important;
}

.h2_04 h2 {
  font-size: 2.5em;
  line-height: 1.3em;
  font-family: 'Sorts Mill Goudy', serif;
  color: #ffffff;
}

.h2_04 h2 .small {
  display: block;
  font-size: 13px;
}

@media only screen and (max-width: 834px) {
  .h2_04 h2 {
    font-size: 2em;
  }
}

.h2_05 h2 {
  font-size: 2.5em;
  line-height: 1.3em;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  color: #ffffff;
}

.h2_05 h2 .small {
  display: block;
  font-size: 13px;
}

@media only screen and (max-width: 834px) {
  .h2_05 h2 {
    font-size: 2em;
  }
}

.h3_01 h3 {
  font-size: 1.25em;
  color: #ffffff;
  padding-bottom: 10px;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  border-bottom: 1px solid #f92d16;
}

@media only screen and (max-width: 640px) {
  .h3_01 h3 {
    font-size: 1.3em;
  }
}

.h3_02 h3 {
  font-size: 1.5em;
  color: #ffffff;
  font-weight: bold;
  border-bottom: 1px solid #f92d16;
  padding-bottom: 5px;
}

@media only screen and (max-width: 640px) {
  .h3_02 h3 {
    font-size: 1em;
  }
}

.h3_02 h3 span {
  color: #DC143C;
}

.blog #contents {
  color: #ffffff;
}

.blog #contents h2 {
  color: #ffffff;
}

.contact_box {
  background: #ffffff;
  padding: 30px;
  border: 1px solid #f92d16;
}

.contact_box .cont_txt div {
  font-size: 1.25em;
  font-weight: bold;
}

.contact_box .cont_txt div br {
  display: none;
}

@media only screen and (max-width: 640px) {
  .contact_box .cont_txt div br {
    display: block;
  }
}

.contact_box .time_txt {
  color: #ffffff;
}

.contact_box .time_txt .lunch {
  width: 30px;
  display: inline-block;
  background: #ff0000;
  text-align: center;
  color: #ffffff;
  margin-bottom: 5px;
}

.contact_box .time_txt .dinner {
  background: #111;
  width: 30px;
  display: inline-block;
  text-align: center;
  color: #ffffff;
}

.contact_box .take_tel div {
  font-size: 1.5em;
  font-weight: bold;
  color: #ffffff;
}

.contact_box .take_tel div a {
  font-weight: bold;
  color: #ffffff;
}

.list_drink li {
  background: #f92d16;
  color: #ffffff;
  font-weight: bold;
  padding: 10px;
  border-radius: 5px;
  margin: 5px;
}

.atten_list {
  border: 4px double #f92d16;
  padding: 30px;
}

.atten_list li {
  color: #ffffff;
}

.contbox {
  justify-content: space-between;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .contbox {
    justify-content: center;
  }
}

.contbox .box {
  width: 31%;
  color: #ffffff;
}

@media only screen and (max-width: 640px) {
  .contbox .box {
    width: 100%;
    margin: 10px 0;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .contbox .box {
    width: 46%;
    margin: 10px;
  }
}

.contbox .box article div {
  font-size: 1.25em;
  text-align: center;
  padding: 15px 0;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.contbox2 {
  justify-content: space-between;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .contbox2 {
    justify-content: center;
  }
}

.contbox2 .box {
  width: 31%;
  background: url(../images/home/news_border_bg.jpg) no-repeat;
  background-size: cover;
  padding: 15px;
}

@media only screen and (max-width: 640px) {
  .contbox2 .box {
    width: 100%;
    margin: 15px 0;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .contbox2 .box {
    width: 46%;
    margin: 15px;
  }
}

.contbox2 .box .price {
  text-align: right;
}

.contbox2 .box h3 {
  font-size: 1.25em;
  font-weight: bold;
  text-align: center;
  padding: 15px 0;
}

.txt {
  line-height: 2em;
}

.mail {
  display: none;
}

.cont_tel div {
  font-size: 1.8em;
  font-weight: bold;
  line-height: 1.25em;
  color: #ffffff;
}

.cont_tel div span {
  font-size: 18px;
  color: #ffffff;
}

.cont_tel a {
  color: #ffffff;
  font-weight: bold;
}

.confirm {
  margin-top: 50px;
}

.cont_form {
  padding: 30px;
  background: #f7f7f7;
}

.cont_form dd .textarea {
  height: 250px;
}

.cont_form dl {
  border-bottom: 1px solid #fff;
  margin: 10px;
}

.cont_01 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  color: #ffffff;
}

.cont_01 .flxL {
  width: 48%;
}

.cont_01 .flxR {
  width: 48%;
}

.cont_01 .flxR .price_txt div {
  font-size: 1.8em;
  font-weight: bold;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

@media only screen and (max-width: 834px) {
  .cont_01 {
    display: block;
    padding: 0;
  }
  .cont_01 .flxL {
    width: 100% !important;
    text-align: center;
  }
  .cont_01 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.cont_02 {
  color: #ffffff;
  background: url(../images/food/contact_bg2.jpg);
  background-size: cover;
  padding: 50px 10px;
}

.cont_02 .cont_tel2 {
  font-size: 1.8em;
  font-family: "Times New Roman";
}

.cont_02 .cont_tel2 a {
  color: #ffffff;
}

.cont_02 .cont_tel2 i {
  margin-right: 7px;
}

.cont_03 {
  color: #ffffff;
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  align-items: center;
}

.cont_03 .flxL {
  width: 48%;
  padding: 10px;
}

.cont_03 .flxR {
  width: 48%;
  text-align: center;
}

@media only screen and (max-width: 834px) {
  .cont_03 {
    display: block;
  }
  .cont_03 .flxL {
    width: 100% !important;
  }
  .cont_03 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.cont_04 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  background-color: rgba(251, 231, 194, 0.9);
  padding: 15px;
}

.cont_04 .flxL {
  width: 28%;
}

.cont_04 .flxR {
  width: 68%;
}

.cont_04 .list_menu li {
  font-size: 1.25em;
}

.cont_04 .txt_ex div {
  font-size: 1.25em;
  font-weight: bold;
  background: #f92d16;
  color: #ffffff;
  padding: 15px;
}

@media only screen and (max-width: 640px) {
  .cont_04 {
    display: block;
  }
  .cont_04 .flxL {
    width: 100% !important;
  }
  .cont_04 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.cont_05 {
  display: flex;
  justify-content: space-between;
}

.cont_05 .dl_menu {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .cont_05 {
    display: block;
  }
  .cont_05 .dl_menu {
    width: 100% !important;
  }
}

.cont_06 {
  display: flex;
  justify-content: space-between;
}

.cont_06 .flxL {
  width: 48%;
}

.cont_06 .flxR {
  width: 48%;
}

@media only screen and (max-width: 834px) {
  .cont_06 {
    display: block;
  }
  .cont_06 .flxL {
    width: 100% !important;
  }
  .cont_06 .flxR {
    width: 100% !important;
    margin-top: 100px;
  }
}

@media screen and (min-width: 834px) and (max-width: 1030px) {
  .cont_06 {
    display: block;
  }
  .cont_06 .flxL {
    width: 100% !important;
  }
  .cont_06 .flxR {
    width: 100% !important;
    margin-top: 100px;
  }
}

.dl_tempo {
  color: #ffffff;
}

.dl_tempo dt {
  border: none !important;
  flex-direction: inherit !important;
  justify-content: flex-start !important;
}

.dl_tempo dd {
  border: none !important;
}

.dl_tempo dl {
  border-bottom: 1px dotted #fff;
}

.dl_menu {
  color: #ffffff;
}

.dl_menu dt {
  border: none !important;
  display: block !important;
  width: 100% !important;
  padding: 0 !important;
}

.dl_menu dd {
  border: none !important;
  width: 100% !important;
  text-align: right;
  font-weight: bold;
  font-size: 1.25em;
  padding: 0 !important;
  position: relative;
}

@media only screen and (max-width: 640px) {
  .dl_menu dd {
    font-size: 1em;
  }
}

@media screen and (min-width: 834px) and (max-width: 1030px) {
  .dl_menu dd {
    font-size: 1em;
  }
}

.dl_menu dd .osusume:before {
  content: 'オススメ！';
  position: absolute;
  right: 0;
  top: -80%;
  color: #f92d16;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.dl_menu dd .ex_txt {
  font-size: 13px;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.dl_menu dl {
  display: block !important;
  padding: 10px;
  border-bottom: 1px solid #f92d16;
}

.dl_01 {
  color: #ffffff;
}

.dl_01 dt {
  align-items: flex-start !important;
  padding: 0 !important;
  border: none !important;
  flex-direction: inherit !important;
  justify-content: flex-start !important;
}

@media only screen and (max-width: 640px) {
  .dl_01 dt {
    width: 100% !important;
    display: block !important;
  }
}

.dl_01 dd {
  padding: 0 !important;
  border: none !important;
}

@media only screen and (max-width: 640px) {
  .dl_01 dd {
    width: 100% !important;
  }
}

.dl_01 dl {
  border-bottom: 1px dotted #ffffff;
  padding: 15px !important;
}

@media only screen and (max-width: 640px) {
  .dl_01 dl {
    display: block !important;
  }
}

@media only screen and (max-width: 640px) {
  .tel_list li {
    width: 100%;
  }
}

.tel_list li .tel2 {
  font-size: 2em;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

@media only screen and (max-width: 640px) {
  .tel_list li .tel2 {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 640px) {
  .tel_list li .tel2 {
    font-size: 1.25em;
    margin-right: 0;
    justify-content: flex-start;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .tel_list li .tel2 {
    font-size: 1.25em;
  }
}

.tel_list li .tel2 img {
  margin-right: 7px;
}

@media only screen and (max-width: 834px) {
  .tel_list li .tel2 img {
    max-width: calc(100% / 7);
  }
}

.tel_list li .tel2 a {
  color: #111111;
  font-weight: bold;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .tel_list li .cont {
    margin-top: 15px;
  }
}

.tel_list li .cont a {
  background: #ffffff;
  color: #ffffff;
  font-size: 1.3em;
  padding: 15px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-weight: bold;
}

@media only screen and (max-width: 834px) {
  .tel_list li .cont a {
    font-size: 1.25em;
    padding: 10px;
  }
}

.tel_list li .cont a img {
  margin-right: 7px;
}

@media only screen and (max-width: 834px) {
  .tel_list li .cont a img {
    max-width: calc(100% / 7);
  }
}

.qa dl {
  display: block !important;
  margin: 15px 0;
  background: #f7f7f7;
  padding: 15px;
}

.qa dt {
  display: block !important;
  width: 100% !important;
  border: none !important;
  position: relative;
  padding: 15px 0 15px 45px !important;
}

@media only screen and (max-width: 834px) {
  .qa dt {
    padding-right: 45px !important;
  }
}

.qa dd {
  width: 100% !important;
  border: none !important;
  padding: 15px 0 15px 45px !important;
  position: relative;
}

.qa dd span {
  font-size: 1.25em;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-weight: bold;
}

.qa dt:before {
  content: "Q";
  display: inline-block;
  line-height: 40px;
  padding: 0em;
  color: white;
  background: #f92d16;
  font-weight: bold;
  width: 40px;
  text-align: center;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 10px;
}

.qa dd:before {
  content: "A";
  display: inline-block;
  line-height: 40px;
  padding: 0em;
  color: white;
  background: #ffffff;
  font-weight: bold;
  width: 40px;
  text-align: center;
  height: 40px;
  margin-right: 7px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 10px;
}

.dl_03 dt {
  border: none !important;
  display: block !important;
  width: 100% !important;
  padding: 0 !important;
  font-weight: bold;
}

.dl_03 dd {
  border: none !important;
  width: 100% !important;
  text-align: right;
  padding: 0 !important;
}

.dl_03 dl {
  border-bottom: 1px dashed #ccc;
  display: block !important;
  padding: 10px 0 !important;
}

.cont_form a {
  color: #111111;
}

.cont_form a:hover {
  text-decoration: underline;
}

.con100 {
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

.itext {
  width: 100% !important;
  height: auto !important;
}

.imgbox {
  justify-content: space-between;
}

.imgbox .box {
  width: 46%;
  margin: 10px;
}

@media only screen and (max-width: 640px) {
  .imgbox .box {
    width: 100% !important;
  }
}

.imgbox2 {
  justify-content: space-between;
}

.imgbox2 .box {
  width: 31%;
}

.dl_info {
  color: #ffffff;
}

.dl_info dt {
  border: none !important;
  padding: 0 !important;
}

.dl_info dd {
  border: none !important;
  padding: 0 !important;
}

.dl_info dl {
  border-bottom: 1px dotted #ccc !important;
  padding: 20px !important;
}

.dl_info dl:nth-of-type(2n) {
  background: #333;
}

.dl_info dl:nth-of-type(2n+1) {
  background: #ffffff;
}

.dl_info .lunch {
  width: 30px;
  display: inline-block;
  background: #f92d16;
  text-align: center;
  color: #ffffff;
  margin-bottom: 5px;
}

.dl_info .dinner {
  background: #111;
  width: 30px;
  display: inline-block;
  text-align: center;
  color: #ffffff;
}

.cont_txt {
  color: #ffffff;
}

.dl_02 dt {
  border: none !important;
  color: #ffffff;
  padding: 0 !important;
}

.dl_02 dd {
  border: none !important;
  text-align: left;
  padding: 0 !important;
}

.dl_02 dl {
  border-bottom: 1px dashed #ccc !important;
  padding: 10px 0 !important;
}

.btnlist li {
  width: 33%;
}

@media only screen and (max-width: 640px) {
  .btnlist li {
    width: 100% !important;
  }
}

.btnlist li a {
  background: #FF768B;
  padding: 15px;
  display: block;
  color: #ffffff;
  font-weight: bold;
  margin: 10px;
  border-radius: 5px;
}

.gmap {
  z-index: 0;
}

.gmap iframe {
  width: 100%;
  height: 700px;
  min-height: 525px;
}

.gmap2 iframe {
  width: 100%;
}

.slider2 img {
  margin: 0 auto;
}

.bg_100per_wrap {
  position: relative;
  padding: 500px 0 !important;
}

@media only screen and (max-width: 640px) {
  .bg_100per_wrap {
    padding: 550px 0 !important;
  }
}

.bg_100per_inner {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: url(../images/common/cont2_bg.jpg);
  background-size: cover;
  z-index: 0;
  display: flex;
  justify-content: flex-end;
  background-attachment: fixed;
}

@media only screen and (max-width: 834px) {
  .bg_100per_inner {
    display: block;
  }
}

.submit {
  margin: 0 auto !important;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 1px solid #f92d16;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 640px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.policy {
  padding: 10px 0;
  text-align: center;
  color: #111111;
}

.policy a {
  color: #f92d16;
}

#TRANS_ANNOT {
  display: none;
}

.slide-bottom {
  opacity: 0;
  transform: translate(0, 50px);
  transition: all 1500ms;
}

.slide-bottom.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-top {
  opacity: 0;
  transform: translate(0, -50px);
  transition: all 1500ms;
}

.slide-top.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-right {
  opacity: 0;
  transform: translate(50px, 0px);
  transition: all 1500ms;
}

.slide-right.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-left {
  opacity: 0;
  transform: translate(-50px, 0px);
  transition: all 1500ms;
}

.slide-left.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

@keyframes fadeIn_left {
  0% {
    opacity: 0;
    transform: translate(-50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn_right {
  0% {
    opacity: 0;
    transform: translate(50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate(0, 50px);
  }
  100% {
    opacity: 1;
  }
}

.sns {
  margin-top: 50px;
}

#media {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
}

#media .row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

#media .row .box {
  width: 31%;
  margin-bottom: 50px;
}

@media only screen and (max-width: 640px) {
  #media .row .box {
    width: 100% !important;
  }
}

#media .row .box h3 {
  text-align: left;
  margin-top: 15px;
}

#media .row .box p {
  text-align: left;
}

#media .row .box img {
  width: 380px !important;
  height: 380px !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}
